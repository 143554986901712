import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvDownloadService } from '@netfoundry-ui/feature/shared-services';
import { PlatformServiceService } from '@netfoundry-ui/shared/apiv2';
import * as yaml from 'js-yaml';
import _ from 'lodash';

const pageSize = 500;

@Component({
    selector: 'app-download-entities',
    templateUrl: './download-entities.component.html',
    styleUrls: ['./download-entities.component.scss'],
})
export class DownloadEntitiesComponent {
    isLoading = false;
    hideHelp = false;
    asCreate = false;
    embedAll = true;
    asCreateType = '';

    constructor(
        private csvDownloadService: CsvDownloadService,
        private platformServiceService: PlatformServiceService,
        private dialogRef: MatDialogRef<DownloadEntitiesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    downloadJSON() {
        this.isLoading = true;
        if (this.data.downloadAll) {
            this._downloadAll('application/json');
        } else if (this.data.downloadNetwork) {
            this._downloadNetwork('application/json');
        } else {
            const items = this.asCreate ? this.data.asCreateItems : this.data.items;
            const beautifiedJson = JSON.stringify(items, null, 2);
            this.csvDownloadService.downloadAll(beautifiedJson, this.data.fileName, undefined, 'application/json');
            this.dialogRef.close();
        }
    }

    downloadYAML() {
        this.isLoading = true;
        if (this.data.downloadAll) {
            this._downloadAll('application/x-yaml');
        } else if (this.data.downloadNetwork) {
            this._downloadNetwork('application/x-yaml');
        } else {
            const items = this.asCreate ? this.data.asCreateItems : this.data.items;
            const yamlText = yaml.dump(items);
            this.csvDownloadService.downloadAll(yamlText, this.data.fileName, undefined, 'application/x-yaml');
            this.isLoading = false;
            this.dialogRef.close();
        }
    }

    downloadCSV() {
        if (this.asCreate) {
            return;
        }
        this.isLoading = true;
        if (this.data.downloadAll) {
            this._downloadAll('text/csv');
        } else {
            const columns = this.asCreate ? this.data.columnsAsCreate : this.data.columns;
            this.csvDownloadService.download(
                this.data.fileName,
                this.data.items,
                columns,
                this.data.translateStatus,
                this.data.isEndpoint,
                undefined,
                false
            );
            this.isLoading = false;
            this.dialogRef.close();
        }
    }

    toggleAsCreate() {
        this.asCreate = !this.asCreate;
        this.asCreateType = this.asCreate ? ';as=create' : '';
    }

    hide() {
        this.dialogRef.close();
    }

    _getQueryParams(pageNum: number, params: HttpParams = new HttpParams()): HttpParams {
        params = params.set('size', pageSize.toString()).set('page', pageNum.toString());
        return params;
    }

    _downloadNetwork(contentType = 'application/json') {
        let networkObj: any = {};
        const networkPromise = this.data
            .entityDownloadService(
                this.data.model.id,
                `application/json${this.asCreateType}`,
                false,
                'app-wans,endpoints,edge-routers,edge-router-policies,posture-checks,certificate-authorities'
            )
            .toPromise()
            .then((result) => {
                networkObj = JSON.parse(result);
            });

        let networkServices = [];
        const servicesPromise = this.platformServiceService
            .getAllServicesByNetworkId(this.data.model.id)
            .then((results) => {
                networkServices = results;
            });

        Promise.all([networkPromise, servicesPromise]).then(() => {
            let textResult = '';
            networkObj.services = networkServices;
            if (contentType === 'application/json') {
                textResult = JSON.stringify(networkObj, null, 2);
            } else {
                textResult = yaml.dump(networkObj);
            }
            this.csvDownloadService.downloadAll(textResult, this.data.fileName, {}, contentType);
            this.dialogRef.close();
        });
    }

    _downloadAll(contentType = 'application/json') {
        const pageResults = [];
        const promiseResults = [];
        let pages = 1;
        if (pageSize < this.data.totalRows) {
            pages = _.ceil(this.data.totalRows / pageSize);
        }

        for (let i = 0; i < pages; i++) {
            const queryParams: HttpParams = this._getQueryParams(i, this.data.params);
            promiseResults.push(
                this.data
                    .entityDownloadService(
                        this.data.currentNetwork.id,
                        `${contentType}${this.asCreateType}`,
                        queryParams
                    )
                    .toPromise()
                    .then((result) => {
                        pageResults[i] = result;
                    })
            );
        }

        Promise.all(promiseResults).then(
            () => {
                let result = [];
                let textResult = '';
                let headerText;
                for (const pageResult of pageResults) {
                    if (contentType === 'application/json') {
                        result = [...result, ...JSON.parse(pageResult)];
                    } else {
                        const lines = pageResult.split('\n');
                        headerText = lines.splice(0, 1);
                        let spacer = '';
                        if (contentType === 'application/x-yaml') {
                            spacer = '\n';
                        }
                        textResult += lines.join('\n') + spacer;
                    }
                }
                if (contentType === 'application/json') {
                    textResult = JSON.stringify(result, null, 2);
                } else {
                    textResult = headerText + '\n' + textResult;
                }
                const options = contentType === 'text/csv' ? { isEndpoint: this.data.entityType === 'Endpoints' } : {};

                this.csvDownloadService.downloadAll(textResult, this.data.fileName, options, contentType);
                this.dialogRef.close();
            },
            () => {
                this.isLoading = false;
            }
        );
    }
}
