import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import {
    AppWanV2,
    AzureSubscription,
    EndpointV2,
    Environment,
    ENVIRONMENT,
    Network,
    NetworkGroup,
    NetworkV2,
    PlatformService,
    Tenant,
    User,
} from '@netfoundry-ui/shared/model';
import {
    BehaviorSubject,
    Observable,
    of as observableOf,
    ReplaySubject,
    Subject,
    throwError as observableThrowError,
} from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';
import { LoggerService } from './logger.service';
import { HTTP_CLIENT, TokenService } from './token.service';
import { v6tov7URLMapping, v7tov6URLMapping } from './url-mappings';

const provisionedStatus = 'PROVISIONED';

@Injectable({ providedIn: 'root' })
export class ApiService {
    /**
     * Make the current network observable by the entire application
     */
    // REPLAY Subject is important because it stores the most current value, not just a change emitter
    currentNetwork = new BehaviorSubject<any>({});

    networkProvisionedSource = new ReplaySubject<boolean>(1);
    networkProvisioned = this.networkProvisionedSource.asObservable();

    availableTenantsSource = new ReplaySubject<any>(1);
    availableTenants = this.availableTenantsSource.asObservable();

    currentAzureSubscriptionSource = new ReplaySubject<AzureSubscription>(1);
    currentAzureSubscription = this.currentAzureSubscriptionSource.asObservable();

    currentContractSource = new ReplaySubject<any>();
    currentContract = this.currentContractSource.asObservable();

    currentOrgSource = new ReplaySubject<NetworkGroup>();
    currentOrg = this.currentOrgSource.asObservable();

    currentUserSource = new ReplaySubject<User>();
    currentUser = this.currentUserSource.asObservable();

    currentUserPreferencesSource = new ReplaySubject<any>();
    currentUserPreferences = this.currentUserPreferencesSource.asObservable();

    currentOrgPreferencesSource = new ReplaySubject<any>();
    currentOrgPreferences = this.currentOrgPreferencesSource.asObservable();

    currentTenantSource = new ReplaySubject<Tenant>(1);
    currentTenant = this.currentTenantSource.asObservable();

    currentSubscriptionSource = new ReplaySubject<any>(1);
    currentSubscription = this.currentSubscriptionSource.asObservable();

    currentPaymentProfileSource = new ReplaySubject<any>(1);
    currentPaymentProfile = this.currentPaymentProfileSource.asObservable();

    currentAutoFabricSource = new ReplaySubject<any>(1);
    currentAutoFabric = this.currentAutoFabricSource.asObservable();

    latestEndpointSource = new ReplaySubject<any>(1);
    latestEndpoint = this.latestEndpointSource.asObservable();

    latestServiceSource = new ReplaySubject<any>(1);
    latestService = this.latestServiceSource.asObservable();

    latestAppWANSource = new ReplaySubject<any>(1);
    latestAppWAN = this.latestAppWANSource.asObservable();

    tutorialEndpointSource = new ReplaySubject<EndpointV2>(1);
    tutorialEndpoint = this.tutorialEndpointSource.asObservable();

    tutorialServiceSource = new ReplaySubject<PlatformService>(1);
    tutorialService = this.tutorialServiceSource.asObservable();

    tutorialAppWANSource = new ReplaySubject<AppWanV2>(1);
    tutorialAppWAN = this.tutorialAppWANSource.asObservable();

    billingStatusInvalidSource = new ReplaySubject<boolean>(1);
    billingStatusInvalid = this.billingStatusInvalidSource.asObservable();

    tutorialStepsSource = new ReplaySubject<[]>(1);
    tutorialSteps = this.tutorialStepsSource.asObservable();

    tutorialStepSource = new ReplaySubject<[]>(1);
    tutorialStep = this.tutorialStepSource.asObservable();
    appLoadingSource = new ReplaySubject<boolean>(1);
    appLoading = this.appLoadingSource.asObservable();

    theBillingStatusInvalidIs = false;
    theAzureSubscriptionIs = new AzureSubscription({});
    theNetworkIs = new Network({});
    theOrgIs = new NetworkGroup({});
    theTenantIs = new Tenant({});
    theUserIs = new User();
    theContractIs = {};
    theUserPreferencesAre: any = {};
    theOrgPreferencesAre: any = {};
    theSubscriptionIs: any = {};
    thePaymentProfileIs: any = {};
    theNetworkIsProvisioned: boolean;
    theAutoFabricIs: any = {};
    theLatestEndpointIs: any = {};
    theLatestServiceIs: any = {};
    theLatestAppWANIs: any = {};
    theLatestTutorialEndpointIs: any = {};
    theLatestTutorialServiceIs: any = {};
    theLatestTutorialAppWANIs: any = {};
    theTutorialStepsIs: any = [];
    theTutorialStepIs: any = [];
    appLoadingIs: any = false;
    theAvailableTenantsAre: any = [];

    lastErrorSource = new Subject<HttpErrorResponse>();
    lastError = this.lastErrorSource.asObservable();

    public tutorialActive = false;
    public productFamily;
    public productVersion;
    currentNetworkGroupSource = new ReplaySubject<NetworkGroup>();
    currentNetworkGroup = this.currentNetworkGroupSource.asObservable();
    addedFromAnywhere: EventEmitter<boolean> = new EventEmitter();
    public openNewEndpointDialog = new EventEmitter();
    public closeNewEndpointDialog = new EventEmitter();
    public openNewServiceDialog = new EventEmitter();
    public closeNewServiceDialog = new EventEmitter();
    public openNewAppWANDialog = new EventEmitter();
    public closeNewAppWANDialog = new EventEmitter();
    private defaultproductversion = 6;

    // Had to use the old http client because the new client produced odd bugs with the Edge service
    constructor(
        private logger: LoggerService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        private tokenService: TokenService,
        @Inject(ENVIRONMENT) private environment: Environment,
        private router: Router,
        private cookieService: CookieService
    ) {
        this.currentOrg.subscribe((data) => {
            this.currentNetworkGroupSource.next(data);
        });
    }

    /**
     * Generic GETter
     */
    get(path: string, params?: any, isV2Api = false) {
        const fullpath = this._path(path, isV2Api);
        // this.logger.debug('GET to path: ' + fullpath);

        if (params == null) {
            params = {};
        }

        if (params.pageNumber !== undefined) {
            // if page size is not provided, use the default provided as part of the config
            if (params.pageSize === undefined) {
                params.pageSize = this.environment.pageSize;
            }
        }

        let headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (isV2Api) {
                headers = headers.append('Accept', 'application/hal+json');
            } else {
                headers.append('Content-Type', 'application/json');
            }

            const options = {
                headers: headers,
                params: params,
            };

            return this.http.get(fullpath, options).pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic GETter
     */
    getBlob(path: string, params?: any): Observable<any> {
        const fullpath = this._path(path);
        // this.logger.debug('GET to path: ' + fullpath);

        if (params == null) {
            params = {};
        }

        if (params.pageNumber !== undefined) {
            // if page size is not provided, use the default provided as part of the config
            if (params.pageSize === undefined) {
                params.pageSize = this.environment.pageSize;
            }
        }

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, {
                    params: params,
                    headers: headers.append('Content-Type', 'application/json'),
                    responseType: 'blob',
                })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic PUTer
     */
    put(path: string, body: unknown = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .put(this._path(path), JSON.stringify(body), {
                    headers: headers.append('Content-Type', 'application/json'),
                })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic POSTer
     */
    post(path: string, body: unknown = {}, params: unknown = {}): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // Angular's HttpParams object is immutable....WHYYYY?
        let parameters = new HttpParams();
        for (const key of Object.keys(params)) {
            parameters = parameters.set(key, params[key]);
        }

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .post(this._path(path), JSON.stringify(body), {
                    headers: headers.append('Content-Type', 'application/json'),
                    params: parameters,
                })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic DELETE
     */
    delete(path, body: any): Observable<any> {
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            let options;

            if (body) {
                options = {
                    headers: this.setHeaders().append('Content-Type', 'application/json'),
                    responseType: 'text',
                    body: JSON.stringify(body),
                };
            } else {
                options = {
                    headers: this.setHeaders().append('Content-Type', 'application/json'),
                    responseType: 'text',
                };
            }

            return this.http
                .delete(this._path(path), options)
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Returns raw resource response from the API
     */
    getLinkedResource(model: any, resourceName: string, params?: any) {
        if (model._links[resourceName] !== undefined && model._links[resourceName]['href'] !== undefined) {
            return this.get(model._links[resourceName]['href'], params);
        } else {
            this.logger.info('No resource link with name: ' + resourceName, model);
            // noinspection TypeScriptUnresolvedFunction
            return observableOf({});
        }
    }

    /**
     * Tells the rest of the application what azure subscription we're on
     */
    setCurrentAzureSubscription(azureSubscription: AzureSubscription) {
        this.currentAzureSubscriptionSource.next(azureSubscription);
        this.theAzureSubscriptionIs = azureSubscription;
    }

    setNetworkProvisioned(provisioned: boolean) {
        this.networkProvisionedSource.next(provisioned);
        this.theNetworkIsProvisioned = provisioned;
    }

    /**
     * Tells the rest of the application what network we're on
     */
    setCurrentOrg(org: NetworkGroup) {
        this.currentOrgSource.next(org);
        this.theOrgIs = org;
    }

    setCurrentContract(contract: any) {
      this.currentContractSource.next(contract);
      this.theContractIs = contract;
    }

    setCurrentUser(user) {
        this.currentUserSource.next(user);
        this.theUserIs = user;
    }

    setCurrentUserPreferences(userPreferences) {
        this.currentUserPreferencesSource.next(userPreferences);
        this.theUserPreferencesAre = userPreferences;
    }

    setCurrentOrgPreferences(orgPreferences) {
        this.currentOrgPreferencesSource.next(orgPreferences);
        this.theOrgPreferencesAre = orgPreferences;
    }

    setAvailableTenants(tenants: any) {
        this.availableTenantsSource.next(tenants);
        this.theAvailableTenantsAre = tenants;
    }

    /**
     * Tells the rest of the application what network we're on
     */
    setCurrentTenant(tenant: Tenant) {
        this.currentTenantSource.next(tenant);
        this.theTenantIs = tenant;
    }

    setAutoFabric(fabric: any) {
        this.currentAutoFabricSource.next(fabric);
        this.theAutoFabricIs = fabric;
    }

    setLatestEndpoint(endpoint: any) {
        this.latestEndpointSource.next(endpoint);
        this.theLatestEndpointIs = endpoint;
    }

    setLatestService(service: any) {
        this.latestServiceSource.next(service);
        this.theLatestServiceIs = service;
    }

    setLatestAppWAN(appWAN: any) {
        this.latestAppWANSource.next(appWAN);
        this.theLatestAppWANIs = appWAN;
    }

    setTutorialEndpoint(endpoint: EndpointV2) {
        this.tutorialEndpointSource.next(endpoint);
        this.theLatestTutorialEndpointIs = endpoint;
    }

    setTutorialService(service: PlatformService) {
        this.tutorialServiceSource.next(service);
        this.theLatestTutorialServiceIs = service;
    }

    setTutorialAppWAN(appWAN: AppWanV2) {
        this.tutorialAppWANSource.next(appWAN);
        this.theLatestTutorialAppWANIs = appWAN;
    }

    setSubscription(subscription: any) {
        this.currentSubscriptionSource.next(subscription);
        this.theSubscriptionIs = subscription;
    }

    setPaymentProfile(paymentProfile: any) {
        this.currentPaymentProfileSource.next(paymentProfile);
        this.thePaymentProfileIs = paymentProfile;
    }

    setBillingStatusInvalid(status: boolean) {
        this.billingStatusInvalidSource.next(status);
        this.theBillingStatusInvalidIs = status;
    }
    setAppLoading(status: boolean) {
        this.appLoadingSource.next(status);
        this.appLoadingIs = status;
    }
    setTutorialSteps(steps: any) {
        this.tutorialStepsSource.next(steps);
        this.theTutorialStepsIs = steps;
    }

    setTutorialStep(step: any) {
        this.tutorialStepSource.next(step);
        this.theTutorialStepIs = step;
    }

    getNetworkVersion(network) {
        if (network.productVersion === undefined) {
            return this.defaultproductversion;
        }

        let splitVersionNumber = network.productVersion.split('.');
        splitVersionNumber = parseInt(splitVersionNumber[0], 10);
        return splitVersionNumber;
    }

    getNetworkMinorVersion(network) {
        if (network.productVersion === undefined) {
            return 0;
        }

        let splitVersionNumber = network.productVersion.split('.');
        if (splitVersionNumber.length >= 2) {
            splitVersionNumber = parseInt(splitVersionNumber[1], 10);
            return splitVersionNumber;
        } else {
            return 0;
        }
    }

    getNetworkPatchVersion(network) {
        if (network.productVersion === undefined) {
            return 0;
        }

        let splitVersionNumber = network.productVersion.split('.');
        if (splitVersionNumber.length >= 3) {
            splitVersionNumber = parseInt(splitVersionNumber[2], 10);
            return splitVersionNumber;
        } else {
            return 0;
        }
    }

    getNetworkFeatures(network: Network) {
        const headers = new HttpHeaders().set('Accept', 'application/hal+json');
        const params = new HttpParams();
        const version = network.productVersion;
        return this.http.get(this.environment.v2apiUrl + 'network-versions?networkVersion=' + version, {
            headers: headers,
            params: params,
        });
    }

    setCurrentNetwork(network: any) {
        if (!network.isPlaceholder) {
            network = this.getNetworkModel(network);
        }
        const splitVersionNumber = this.getNetworkVersion(network);

        this.productVersion = network.productVersion;
        this.currentNetwork.next(network);

        let newRoute = null;
        const networkEmpty = _.isEmpty(network?.id);
        if (!networkEmpty && splitVersionNumber < 7) {
            this.theNetworkIs = network;
            this.productFamily = network.productFamily;
            newRoute = v7tov6URLMapping[this.router.url];
        } else {
            this.theNetworkIs = network;
            this.setNetworkProvisioned(network.status === provisionedStatus);
            this.productFamily = 'ZITI';
            newRoute = v6tov7URLMapping[this.router.url];
        }
        const selfLink = network._links?.self.href;
        this.cookieService.delete('currentNetwork', '/');
        this.cookieService.delete('currentNetwork', '/v7');
        this.cookieService.set('currentNetwork', selfLink, undefined, '/');

        if (newRoute != null) {
            this.router.navigate([newRoute]);
        }

        if (network && network.productVersion) {
            this.getNetworkFeatures(network).subscribe((features) => {
                const zitiFeatures = _.get(features, '_embedded.network-versions[0].zitiFeatures');
                sessionStorage.setItem('networkFeatures', JSON.stringify(zitiFeatures));
            });
        }
    }

    // get the correct model type when you don't know what it's going to be
    getNetworkModel(model): any {
        if (model.productVersion === undefined) {
            return new Network({});
        }

        let network;
        if (this.getNetworkVersion(model) < 7) {
            network = new Network(model);
        } else {
            network = _.merge(new NetworkV2(), model);
        }

        const controllers = _.get(network, 'network-controllers', _.get(network, '_embedded.network-controllers'));
        if (!_.isEmpty(controllers)) {
            network.networkController = controllers[0];
        }
        return network;
    }

    triggerAddedFromAnywhere() {
        this.addedFromAnywhere.emit(true);
    }

    getNetworkStatusLabel(network) {
        const version = this.getNetworkVersion(network);

        if (version >= 7) {
            return network.status;
        }

        // otherwise translate v6 networks
        if (network.status < 300) {
            return 'BUILDING';
        } else if (network.status === 300) {
            return 'PROVISIONED';
        } else if (network.status === 600) {
            return 'UPDATING';
        } else {
            return 'ERROR';
        }
    }

    /**
     * Wrapper function to handle full URLs vs relative URLs.
     */
    protected _path(path: string, isV2Api = false) {
        if (typeof path.includes !== 'function') {
            this.logger.error(
                'Unexpected destination passed for an HTTP request. Trying to request against an endpoint of: ' + path
            );
        }

        if (path.includes(this.environment.apiUrl) || path.includes('http://') || path.includes('https://')) {
            return path;
        } else {
            const url = isV2Api ? this.environment.v2apiUrl : this.environment.apiUrl;
            return `${url}${path}`;
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.logger.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.logger.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        return headers;
    }
}
