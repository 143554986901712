import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { catchError, delay } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

import _ from 'lodash';
import { HTTP_CLIENT, TokenService } from './token.service';
import { LoggerService } from './logger.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class IdentityPreferencesService {
    apiUrl;
    preferences;
    user;

    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiUrl = _.get(environment, 'identityConfig.url');
        this.apiService.currentUser.subscribe((user) => {
            this.user = user;
            this.getPreferences();
        });
    }

    public getPreferences() {
        if (_.isEmpty(this.user)) {
            return Promise.resolve();
        }
        const identId = _.get(this.user, 'identityMappings[0].userIdentityId', '');
        return this.get('identity-preferences', identId)
            .toPromise()
            .then((result: any) => {
                this.preferences = result[0] || {};
                this.apiService.setCurrentUserPreferences(this.preferences);
                return this.preferences;
            })
            .catch((result: any) => {
                this.preferences = { preferences: {} };
                this.apiService.setCurrentUserPreferences(this.preferences);
                this.logger.error('Unable to retrieve user preferences');
            });
    }

    public setPreferences(keyValuePairs: any) {
        keyValuePairs.forEach((keyValuePair) => {
            _.set(this.preferences, `preferences.${keyValuePair.key}`, keyValuePair.value);
        });
        const identId = _.get(this.preferences, 'id', '');
        return this.set(identId, this.preferences)
            .toPromise()
            .then((result: any) => {
                this.preferences = result;
                this.apiService.setCurrentUserPreferences(this.preferences);
                return this.preferences;
            })
            .catch((result: any) => {
                this.preferences = { preferences: {} };
                this.apiService.setCurrentUserPreferences(this.preferences);
                this.logger.error('Unable to set user preferences');
            });
    }

    public setPreference(key, value) {
        _.set(this.preferences, `preferences.${key}`, value);
        const identId = _.get(this.preferences, 'id', '');
        return this.set(identId, this.preferences)
            .toPromise()
            .then((result: any) => {
                this.preferences = result;
                this.apiService.setCurrentUserPreferences(this.preferences);
                return this.preferences;
            })
            .catch((result: any) => {
                this.preferences = { preferences: {} };
                this.apiService.setCurrentUserPreferences(this.preferences);
                this.logger.error('Unable to set user preferences');
            });
    }

    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public get(type: string, id: string) {
        let fullpath = `${this.apiUrl}${type}`;
        if (id) {
            fullpath += `?userIdentityId=${id}`;
        }
        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();

        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            return this.http
                .get(fullpath, { headers: headers })
                .pipe(catchError((error) => this.handleError(this, error)));
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Get item by ID
     * Allowed Types:
     *    identity-permission
     *    user-identities
     *    invitations
     *    tenants
     *    operations
     */
    public set(id: string, preferences: any) {
        let fullpath = `${this.apiUrl}identity-preferences/${id}`;

        // getting the headers. This now checks to determine if the user's token expired
        const headers = this.setHeaders();
        const body = {
            userIdentityId: preferences?.userIdentityId,
            preferences: preferences?.preferences,
        };
        // headers will be not null if the token is valid. If this is the case, kick off the request
        if (headers != null) {
            if (!body.userIdentityId) {
                fullpath = `${this.apiUrl}identity-preferences/`;
                const identId = _.get(this.user, 'identityMappings[0].userIdentityId', '');
                body.userIdentityId = identId;
                return this.http
                    .post(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            } else {
                return this.http
                    .patch(fullpath, JSON.stringify(body), { headers: headers })
                    .pipe(catchError((error) => this.handleError(this, error)));
            }
        } else {
            // delaying the response to allow the console time to trigger the logout and terminate the subscription
            return observableOf([]).pipe(delay(1000));
        }
    }

    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Feature Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Feature Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

    /**
     * Return headers
     */
    private setHeaders(): HttpHeaders {
        // getting the access token
        const tokenResponse = this.tokenService.getAccessToken();

        // value to return
        let headers;

        if (tokenResponse.expired) {
            // if the token is expired, return null
            headers = null;
        } else if (tokenResponse.accessToken) {
            // if the token was not expired and there was an access token returned, set the headers using the access token
            headers = new HttpHeaders().set('Authorization', 'Bearer ' + tokenResponse.accessToken);
        } else {
            // if there were no headers return a new set of HTTP headers without an access token
            headers = new HttpHeaders();
        }

        if (headers != null) {
            return headers.append('Content-Type', 'application/json');
        } else {
            return headers;
        }
    }
}
