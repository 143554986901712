import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ConsoleTourComponent } from '@netfoundry-ui/feature/console-tour';
import { OrganizationPromptComponent } from '@netfoundry-ui/feature/form/organization-prompt';
import { TicketFormComponent } from '@netfoundry-ui/feature/form/ticket-form';
import { V2NetworkCreationFormComponent } from '@netfoundry-ui/feature/form/v2-network-creation-form';
import { AccountService, BillingEnabledService, BillingService } from '@netfoundry-ui/feature/shared-services';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { NetworkUpgradeService } from '@netfoundry-ui/feature-network-upgrade';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import {
    _VERSION,
    AzureSubscription,
    ENVIRONMENT,
    Environment,
    Network,
    NetworkGroup,
    NetworkV2,
    Tenant,
} from '@netfoundry-ui/shared/model';
import {
    ApiService,
    BrandingModeService,
    FeatureService,
    LoggerService,
    MenuService,
    NetworkService,
    RefresherService,
    TourService,
    URLS,
    ZitiEnabledService,
} from '@netfoundry-ui/shared/services';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { includes } from 'lodash';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit, OnDestroy {
    @Output() showMenuEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() showGrowlersEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() networkChange: EventEmitter<string> = new EventEmitter();
    @Output() showPageTour: EventEmitter<string> = new EventEmitter();
    @Output() addEvent: EventEmitter<boolean> = new EventEmitter();

    areas = [...this.menuService.areas];
    area = '';
    open = false;
    menuSub = new Subscription();

    /** From Old Component */
    subscription = new Subscription();
    azureEnabled = false;
    url = '';
    pageArea = '';
    isOpened = false;
    isOpenedTenant = false;
    currentNetwork: Network | NetworkV2 = new Network({});
    currentOrg = new NetworkGroup({});
    currentAzureSubscription = new AzureSubscription({});
    currentTenant: Tenant = new Tenant({});
    canListAppWans = true;
    canListEndpoints = true;
    canListGroups = true;
    canListServices = true;
    canListSubscriptions = true;
    azureSubscriptionId = null;
    azureSubscriptionSub = new Subscription();
    public selectedNetwork: string;
    public networks = [];
    public v2Networks = [];
    public allNetworks: any;
    dialogRef;
    billingStatusInvalid = false;
    tenantId;
    tenants = [];
    selectedTenant;
    tenantMap = {};
    showTenants = false;
    filterString = '';
    majorVersionNumber;
    minorVersionNumber;
    patchVersionNumber;
    networkCanHavePostureChecks = false;
    networksV7 = [];
    upgradeableNetworks = 0;
    Ziti_Endpoints = URLS.ZITIENDPOINTS;
    Ziti_AppWans = URLS.ZITIAPPS;
    Ziti_Services = URLS.ZITISERVICES;
    Ziti_Service_Edge_Router_Policies = URLS.ZITI_SERVICE_ER_POLICIES;
    Edge_Routers = URLS.ZITIROUTERS;
    Subscriptions_VWAN = URLS.SUBSCRIPTIONS;
    Subscriptions_VIRTUALWAN = URLS.V6_VWAN;
    Subscriptions_AAD = URLS.CLIENT_SYNC_AAD;
    Subscriptions_AD = URLS.CLIENT_SYNC_AD;
    Network_Group_Summary = URLS.NETWORK_GROUP_SUMMARY;
    Resource_Usage = URLS.RESOURCE_USAGE;
    Daily_Stats = URLS.DAILYSTATS;
    Certificate_Authorities = URLS.CERTIFICATE_AUTHORITIES;
    Ziti_Certificate_Authorities = URLS.ZITI_CERTIFICATE_AUTHORITIES;
    Auth_Policies = URLS.AUTH_POLICIES;
    JWT_Signers = URLS.JWT_SIGNERS;
    Billing = URLS.BILLING;
    Users = URLS.USERS;
    User = URLS.USER;
    Manage_Invitations = URLS.MANAGE_INVITATIONS;
    Tokens = URLS.TOKENS;
    Organizations = URLS.ORGANIZATIONS;
    Stored_Secrets = URLS.STORED_SECRETS;
    Settings = URLS.SETTINGS;
    Gateways = URLS.GATEWAYS;
    Clients = URLS.CLIENTS;
    Groups = URLS.GROUPS;
    Azure_Virtual_Wan_Sites = URLS.VIRTUALWANSITES;
    Appwans = URLS.APPWANS;
    Services = URLS.SERVICES;
    Management_Events = URLS.MANAGEMENT_EVENTS;
    Network_Events = URLS.NETWORK_EVENTS;
    Network_Controllers = URLS.NETWORK_CONTROLLERS;
    Allocated_Ips = URLS.ALLOCATED_IPS;
    Metrics = URLS.METRICS;
    Getting_Started = URLS.GETTING_STARTED;
    Alarms = URLS.ALARMS;
    Posture_Checks = URLS.POSTURE_CHECKS;
    Payment_Profile = URLS.PAYMENT_PROFILE;
    Account_Details = URLS.ACCOUNT_DETAILS;
    Billing_Subscriptions = URLS.BILLING_SUBSCRIPTIONS;
    Billing_Subscription_Overages = URLS.BILLING_SUBSCRIPTION_OVERAGES;
    Billing_Subscription_Usage = URLS.BILLING_SUBSCRIPTION_USAGE;
    isSelfServiceTeamsTier = false;
    isSelfService = false;
    currentSubscription;
    showGettingStarted = true;

    disableMainNav = false;
    disabledToolTip = 'Network must be provisioned to access this page';
    provisioning = false;
    selfServiceBilling = false;
    enterpriseBilling = false;
    private isFirstLoad = true;
    private isFirstAdd = true;
    private headerRefresher;
    private nickname = '';
    private apiServiceSub: Subscription = new Subscription();
    private subscriptions: Subscription = new Subscription();
    private networkServiceGetSub: Subscription = new Subscription();
    networkCanSupportBrowZer = false;

    constructor(
        private logger: LoggerService,
        public menuService: MenuService,
        private accountService: AccountService,
        public apiService: ApiService,
        private router: Router,
        private networkService: NetworkService,
        private cookieService: CookieService,
        private refresher: RefresherService,
        private tourService: TourService,
        public brandingService: BrandingModeService,
        public billingEnabledService: BillingEnabledService,
        public dialogForm: MatDialog,
        public authorizationService: AuthorizationService,
        public featureService: FeatureService,
        public zendeskService: NgxZendeskWebwidgetService,
        private iamService: IamService,
        public zitiEnabledService: ZitiEnabledService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        public brandingModeService: BrandingModeService,
        private networkUpgradeService: NetworkUpgradeService,
        private billingService: BillingService,
        @Inject(ENVIRONMENT) private environment: Environment,
        @Inject(_VERSION) public version: any
    ) {
        setTimeout(() => {
            this.zendeskService.zE('webWidget', 'hide');

            this.zendeskService.zE('webWidget', 'updateSettings', {
                webWidget: { zIndex: 999999 },
            });
            this.url = this.router.url.split('?')[0];
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.url = event.url.split('?')[0];
                }
            });
        }, 2000);

        this.headerRefresher = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    get isZiti() {
        return this.apiService.productFamily === 'ZITI';
    }

    async ngOnInit() {
        this.menuSub = this.menuService.area.subscribe((area) => {
            if (this.billingStatusInvalid && !includes(['Support', 'Organization', ''], area)) {
                this.area = 'Organization';
                this.open = true;
                return;
            }
            this.area = area;
            this.open = this.area.length > 0;
            switch (this.area) {
                case 'Super': {
                    this.pageArea = 'Super User Admin';
                    break;
                }
                case 'Support': {
                    this.pageArea = 'Get Support';
                    break;
                }
                default: {
                    this.pageArea = this.area;
                    break;
                }
            }
        });
        if (this.menuService.locked) {
            const area = localStorage.getItem('NavArea');
            if (area != null) {
                this.menuService.setArea(area);
            } else {
                this.menuService.setArea('Network');
            }
        }

        if (!this.authorizationService.canCreateNetworks()) {
            this.showGettingStarted = false;
        }

        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                this.tenantId = tenant.id;
                this.currentTenant = tenant;
                this.loadTenants();
            })
        );

        this.subscription.add(
            this.apiService.billingStatusInvalid.subscribe((status: boolean) => {
                this.billingStatusInvalid = status;
                if (this.billingStatusInvalid) {
                    this.area = 'Organization';
                }
            })
        );

        this.subscription.add(
            this.accountService.currentAccount.subscribe((account) => {
                this.enterpriseBilling = account?.billingMode === 'Enterprise';
            })
        );

        this.subscription.add(
            this.apiService.currentAzureSubscription.subscribe((azureSubscription) => {
                this.currentAzureSubscription = new AzureSubscription(azureSubscription);
                this.updatePermissions();
            })
        );

        // this.subscription.add(this.apiService.currentNetwork.subscribe(result => {
        this.subscription.add(
            this.apiService.currentNetwork.subscribe((result) => {
                if (result.id !== null) {
                    this.majorVersionNumber = this.apiService.getNetworkVersion(result);
                    this.minorVersionNumber = this.apiService.getNetworkMinorVersion(result);
                    this.patchVersionNumber = this.apiService.getNetworkPatchVersion(result);
                }
                this.currentNetwork = result;

                const n2 = result as unknown as NetworkV2;
                this.disableMainNav = !n2?.status || n2?.status === 'PROVISIONING' || n2?.status === 'SUSPENDED';
                this.provisioning = n2?.status === 'PROVISIONING';
                this.updatePermissions();
            })
        );

        this.apiService.currentSubscription.subscribe((subscription) => {
            this.currentSubscription = subscription;
            this.isSelfService = this.featureService.isSelfServiceAccount(subscription);
            this.selfServiceBilling = this.isSelfService && this.authorizationService.canViewPaymentProfile();

            if (
                this.featureService.isSelfServiceTeamsTier(subscription) ||
                this.featureService.isSelfServicePAYG(subscription)
            ) {
                this.isSelfServiceTeamsTier = true;
            }
        });

        this.apiService.currentOrg
            .pipe(take(1))
            .toPromise()
            .then((result) => {
                this.currentOrg = new NetworkGroup(result);
            });

        this.initNetworks();
    }

    handleClickEventNav(event: MouseEvent, route) {
        if (this.disableMainNav) {
            event.stopPropagation();
            event.preventDefault();
            return;
        } else {
            this.router.navigate([route]);
        }
    }

    toggleLock() {
        this.menuService.toggleLock();
    }

    /**
     * Network List Component
     */

    initNetworks() {
        this.apiServiceSub.add(
            this.apiService.currentNetwork.subscribe((network) => {
                const networkVersion = this.apiService.getNetworkVersion(network);
                this.isOpened = false;
                if (network.id !== null) {
                    this.currentNetwork = networkVersion < 7 ? new Network(network) : (network as unknown as NetworkV2);

                    // this.logger.info('SAVE CURRENT NETWORK TO COOKIE', this.currentNetwork);
                    // const selfLink = this.currentNetwork.getSelfLink();
                    const selfLink = this.currentNetwork['_links.self.href'];

                    this.selectedNetwork = selfLink;
                    this.networkChange.emit(selfLink);

                    this.loadNetworks();
                } else {
                    this.currentNetwork = new Network({});
                    this.currentNetwork.name = 'Select A Network';
                    if (this.isFirstAdd) {
                        this.isFirstAdd = false;
                        this.apiService.setCurrentNetwork(new Network({}));
                    }
                }
            })
        );

        this.subscriptions.add(
            this.apiService.currentOrg.subscribe((org) => {
                this.currentOrg = new NetworkGroup(org);
                if (this.authorizationService.canCreateNetworks()) {
                    this.loadNetworks();
                } else {
                    this.networkServiceGetSub.unsubscribe();
                }
            })
        );

        this.loadNetworks();
    }

    closeNetworkList() {
        this.filterString = '';
        this.isOpened = false;
    }

    closeTenantList() {
        this.filterString = '';
        this.isOpenedTenant = false;
    }

    addNetwork() {
        // V6 version of the create form
        // this.dialogRef = this.dialogForm.open(WizardComponent, { data: {}, minHeight: '100%', minWidth: '100%', height: '100%', width: '100%'});

        // V7 compatible form
        this.dialogRef = this.dialogForm.open(V2NetworkCreationFormComponent, {
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    loadTenants() {
        if (this.authorizationService.canListTenants()) {
            this.apiService.availableTenants.subscribe((tenants) => {
                this.tenants = tenants as Tenant[];

                for (const tenant of this.tenants) {
                    this.tenantMap[tenant.id] = tenant;
                }
                this.currentTenant = this.tenantMap[this.tenantId];
                this.tenantId = this.currentTenant.id;
            });
        }
    }

    public setNetwork(network) {
        this.apiService.setCurrentNetwork(network);
        this.closeNetworkList();
    }

    public setTenant(tenant) {
        this.currentTenant = tenant;
        this.tenantId = this.currentTenant.id;
        this.apiService.setCurrentTenant(this.currentTenant);
        this.closeTenantList();
    }

    manageNetworks() {
        this.isOpened = false;
        this.router.navigate(['/networks']);
    }

    toggleList(event: any) {
        event.stopPropagation();
        this.isOpened = !this.isOpened;
    }

    toggleListTenant(event: any) {
        event.stopPropagation();
        this.isOpenedTenant = !this.isOpenedTenant;
    }

    stopProp(event) {
        event.stopImmediatePropagation();
    }

    /** Support Calls */
    openHub() {
        window.open('http://support.netfoundry.io');
    }

    openTicket() {
        this.dialogRef = this.dialogForm.open(TicketFormComponent, {
            data: {},
            height: '695px',
            width: '975px',
            autoFocus: false,
        });
    }

    addTenant() {
        this.dialogRef = this.dialogForm.open(OrganizationPromptComponent, {
            data: {},
            height: '475px',
            width: '855px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.loadTenants();
        });
    }

    openTour() {
        this.dialogRef = this.dialogForm.open(ConsoleTourComponent, {
            data: {},
            height: '455px',
            width: '935px',
            autoFocus: false,
        });
    }

    gotoReleaseNotes() {
        window.open('https://support.netfoundry.io/hc/en-us/sections/360008342332-Tech-Bulletins', '_blank');
    }

    gotoCommunity() {
        window.open('https://community.netfoundry.io', '_blank');
    }

    openPageTour() {
        this.menuService.setArea('');
        this.tourService.show('');
    }

    refresh() {
        clearTimeout(this.headerRefresher);
        this.loadNetworks();
        this.headerRefresher = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    ngOnDestroy() {
        clearTimeout(this.headerRefresher);
        this.subscription.unsubscribe();
        this.menuSub.unsubscribe();
        this.apiServiceSub.unsubscribe();
        this.subscriptions.unsubscribe();
        this.networkServiceGetSub.unsubscribe();
    }

    openSupport() {
        // this.zendeskService.zE("webWidget", 'show');
        this.zendeskService.zE('webWidget', 'open');
    }

    public async loadNetworks() {
        if (this.authorizationService.canListNetworks()) {
            const proms = [];
            proms.push(this._loadV7networks());

            await Promise.all(proms);

            // combine the list
            let combined: any[] = [];
            combined = combined.concat(this.networksV7);

            this.allNetworks = combined;

            if (this.allNetworks.length < 1) {
                this.currentNetwork = new Network({});
                this.currentNetwork.name = 'Select A Network';
                this.apiService.setCurrentNetwork(this.currentNetwork);
            }

            this.upgradeableNetworks = 0;
            this.networksV7.forEach((n) => {
                if (this.networkUpgradeService.isUpgradeable(n)) {
                    this.upgradeableNetworks++;
                }
            });
        }
    }

    canManageSubscriptions() {
        return (
            (this.authorizationService.canListClientSyncs() &&
                !this.isZiti &&
                this.authorizationService.canListAzureSubscriptions()) ||
            this.isZiti
        );
    }

    private updatePermissions() {
        // TODO - add back in azure subscriptions when needed
        if (this.currentAzureSubscription.id != null && this.apiService.getNetworkVersion(this.currentNetwork) < 7) {
            this.azureEnabled = true;
            this.canListSubscriptions = this.authorizationService.canListAzureSubscriptions();
        } else {
            this.azureEnabled = false;

            this.canListSubscriptions = false;

            // this.canListVirtualWans = false;
        }
        this.canListAppWans = this.authorizationService.canListAppWans();
        this.canListServices = this.authorizationService.canListServices();
        this.canListEndpoints = this.authorizationService.canListEndpoints();
        this.canListGroups = this.authorizationService.canListEndpointGroups();

        const minorVersionNumber = this.apiService.getNetworkMinorVersion(this.currentNetwork);
        const patchVersionNumber = this.apiService.getNetworkPatchVersion(this.currentNetwork);

        if (
            this.majorVersionNumber < 7 ||
            (this.majorVersionNumber === 7 && minorVersionNumber < 3) ||
            (this.majorVersionNumber === 7 && minorVersionNumber === 3 && patchVersionNumber < 1)
        ) {
            this.networkCanHavePostureChecks = false;
        } else {
            this.networkCanHavePostureChecks = true;
        }

        if (
            this.majorVersionNumber < 7 ||
            (this.majorVersionNumber === 7 && minorVersionNumber < 3) ||
            (this.majorVersionNumber === 7 && minorVersionNumber === 3 && patchVersionNumber < 90)
        ) {
            this.networkCanSupportBrowZer = false;
        } else {
            this.networkCanSupportBrowZer = true;
        }
    }

    private async _loadV7networks() {
        return await this.networkServiceV2.getNetworksPage({ pageParams: { size: 2000, page: 0 } }).then(
            (networks: NetworkV2[]) => {
                if (this.apiService.getNetworkVersion(this.currentNetwork) === 7) {
                    for (const network of networks) {
                        if (network.id === this.currentNetwork.id && network.status !== this.currentNetwork.status) {
                            this.apiService.setCurrentNetwork(network);
                        }
                    }
                }

                const v2Networks = [];
                for (const v2Network of networks) {
                    // only show v2 networks that are in a living state
                    if (v2Network.status !== 'DELETING' && v2Network.status !== 'DELETED') {
                        v2Networks.push(v2Network as NetworkV2);
                    }
                }

                this.networksV7 = v2Networks;
            }, // @TODO - better handling - 404's are ERROR but OK if no one has V2 networks yet
            (error) => {
                this.logger.error('Error returning V2 networks for side-navigation component', error);
                this.networksV7 = [];
            }
        );
    }
}
