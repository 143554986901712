import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';

@NgModule({
    imports: [CommonModule, RouterModule, ClickOutsideModule],
    declarations: [AppSwitcherComponent],
    exports: [AppSwitcherComponent],
})
export class FeatureAppSwitcherModule {}
