import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadEntitiesComponent } from '@netfoundry-ui/feature/upload-entities';
import { ZitiResourceHelper } from '@netfoundry-ui/feature/ziti-resource-helper';
import { ProcessExecutionServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT, Network, NetworkGroup } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, URLS } from '@netfoundry-ui/shared/services';
import _ from 'lodash';
import { ReplaySubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PagedGetOption } from '@lagoshny/ngx-hateoas-client';

@Component({
    selector: 'app-list-header',
    templateUrl: './list-header.component.html',
    styleUrls: ['./list-header.component.scss'],
})
export class ListHeaderComponent implements OnInit, OnDestroy {
    @Input() title = '';
    @Input() network: any = new Network({});
    @Input() organization = new NetworkGroup({});
    @Input() breadcrumb = '';
    @Input() actionTitle = '';
    @Input() tabs = '';
    @Input() url = '';
    @Input() hasAzureSubscription = false;
    @Input() shortBreadCrumbs = false;

    processes = [];
    activeProcess = false;
    // REPLAY Subject is important because it stores the most current value, not just a change emitter
    currentNetworkSource = new ReplaySubject<any>(1);
    currentNetwork: any = this.currentNetworkSource.asObservable();
    isTeamsTierSub = true;

    isSelfService = false;
    isEnterprise = true;
    tenantLabel = '';
    tenantId = '';
    Subscriptions_VWAN = URLS.SUBSCRIPTIONS;
    Subscriptions_VIRTUALWAN = URLS.V6_VWAN;
    Subscriptions_AAD = URLS.CLIENT_SYNC_AAD;
    Subscriptions_AD = URLS.CLIENT_SYNC_AD;
    Ziti_Endpoints = URLS.ZITIENDPOINTS;
    Ziti_Services = URLS.ZITISERVICES;
    Ziti_Configs = URLS.ZITICONFIGS;
    Ziti_Config_Types = URLS.ZITICONFIGTYPES;
    Network_Group_Summary = URLS.NETWORK_GROUP_SUMMARY;
    Resource_Usage = URLS.RESOURCE_USAGE;
    Daily_Stats = URLS.DAILYSTATS;
    Daily_Endpoint_Utilization = URLS.DAILY_ENDPOINT_UTILIZATION;
    Certificate_Authorities = URLS.CERTIFICATE_AUTHORITIES;
    Ziti_Certificate_Authorities = URLS.ZITI_CERTIFICATE_AUTHORITIES;
    Ziti_AppWans = URLS.ZITIAPPS;
    Edge_Routers = URLS.ZITIROUTERS;
    Billing = URLS.BILLING;
    Users = URLS.USERS;
    UserRoles = URLS.USER_ROLES;
    Manage_Invitations = URLS.MANAGE_INVITATIONS;
    Tokens = URLS.TOKENS;
    Organizations = URLS.ORGANIZATIONS;
    Settings = URLS.SETTINGS;
    Gateways = URLS.GATEWAYS;
    Clients = URLS.CLIENTS;
    Groups = URLS.GROUPS;
    Azure_Virtual_Wan_Sites = URLS.VIRTUALWANSITES;
    Appwans = URLS.APPWANS;
    Services = URLS.SERVICES;
    Notifications = URLS.NOTIFICATIONS;
    Management_Events = URLS.MANAGEMENT_EVENTS;
    Network_Controllers = URLS.NETWORK_CONTROLLERS;
    Allocated_Ips = URLS.ALLOCATED_IPS;
    Stored_Secrets = URLS.STORED_SECRETS;
    Network_Events = URLS.NETWORK_EVENTS;
    Alarms = URLS.ALARMS;
    Posture_Checks = URLS.POSTURE_CHECKS;
    Payment_Profile = URLS.PAYMENT_PROFILE;
    Account_Details = URLS.ACCOUNT_DETAILS;
    Billing_Subscriptions = URLS.BILLING_SUBSCRIPTIONS;
    Billing_Subscription_Overages = URLS.BILLING_SUBSCRIPTION_OVERAGES;
    Billing_Subscription_Usage = URLS.BILLING_SUBSCRIPTION_USAGE;
    Auth_Policies = URLS.AUTH_POLICIES;
    JWT_Signers = URLS.JWT_SIGNERS;
    Browzer = URLS.BROWZER;
    Browzer_Bootstrappers = URLS.BROWZER_BOOTSTRAPPERS
    dialogRef;
    networkVersion = 6;
    hasCreatePermission = false;
    private subscription: Subscription = new Subscription();
    User: string;

    constructor(
        public authorizationService: AuthorizationService,
        private apiService: ApiService,
        public dialogForm: MatDialog,
        public featureService: FeatureService,
        public processExecutionService: ProcessExecutionServiceV2,
        private zitiResourceHelper: ZitiResourceHelper,
        private logger: LoggerService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    _resourceType = '';

    get resourceType(): string {
        return this._resourceType;
    }

    @Input() set resourceType(value: string) {
        this._resourceType = value;
        switch (this._resourceType) {
            case 'ziti-app-wan':
                this.hasCreatePermission = this.authorizationService.canCreateAppWans();
                break;
            case 'ziti-service':
                this.hasCreatePermission = this.authorizationService.canCreateServices();
                break;
            case 'ziti-endpoint':
                this.hasCreatePermission = this.authorizationService.canCreateEndpoints();
                break;
            case 'edge-router':
                this.hasCreatePermission = this.authorizationService.canCreateEdgeRouters();
                break;
            case 'edge-router-policy':
                this.hasCreatePermission = this.authorizationService.canCreateEdgeRouterPolicies();
                break;
            case 'posture-check':
                this.hasCreatePermission = this.authorizationService.canCreatePostureChecks();
                break;
        }
    }

    get uploadText(): string {
        let uploadText = '';
        switch (this._resourceType) {
            case 'ziti-app-wan':
                uploadText = `Upload ${this.featureService?.disableNewNavigation ? 'AppWANs' : 'Service Policies'}`;
                break;
            case 'ziti-service':
                uploadText = 'Upload Services';
                break;
            case 'ziti-endpoint':
                uploadText = `Upload ${this.featureService?.disableNewNavigation ? 'Endpoints' : 'Identities'}`;
                break;
            case 'edge-router':
                uploadText = 'Upload Edge Routers';
                break;
            case 'edge-router-policy':
                uploadText = 'Upload Edge Router Policies';
                break;
            case 'posture-check':
                uploadText = 'Upload Posture Checks';
                break;
        }
        return uploadText;
    }

    ngOnInit() {
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                this.tenantId = tenant.id;
                this.tenantLabel = tenant.label;
            })
        );

        this.apiService.currentNetwork.subscribe((result) => {
            this.networkVersion = this.apiService.getNetworkVersion(result);
        });

        this.subscription.add(
            this.apiService.currentNetwork.pipe(debounceTime(1000)).subscribe((network) => {
                this.currentNetwork = network;
            })
        );

        this.subscription.add(
            this.apiService.currentSubscription.subscribe((subscription) => {
                this.isTeamsTierSub = this.featureService.isSelfServiceTeamsTier(subscription);
                this.isSelfService = this.featureService.isSelfServiceAccount(subscription);
                this.isEnterprise = !this.isSelfService;
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openResourceUploadDialog() {
        this.dialogRef = this.dialogForm.open(UploadEntitiesComponent, {
            data: {
                resourceType: this.resourceType,
            },
            minHeight: '100%',
            minWidth: '100%',
            height: '100%',
            width: '100%',
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _initProcessCheck() {}

    getProcessExecutions() {
        this.processExecutionService
            .getProcessExecutionPage(this.getOptions())
            .then(
                (result) => {
                    this.processes = result;
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    this.activeProcess = _.some(this.processes, (process) => {});
                    _.delay(() => {
                        this.getProcessExecutions();
                    }, 10000);
                },
                (result) => {
                    this.logger.error('Unable to load processes for this network');
                }
            )
            .catch((result) => {
                this.logger.error('Unable to load processes for this network');
            });
    }

    getOptions(): PagedGetOption {
        const options = {
            params: {
                networkId: this.currentNetwork.id,
            },
            pageParams: {
                size: 50,
                page: 0,
            },
        };
        return options;
    }

    showUploadLink() {
        if (!this.isEnterprise || this.environment.v3Enabled) {
            return false;
        }

        switch (this._resourceType) {
            case 'ziti-app-wan':
                return this.authorizationService.canCreateAppWans();
            case 'ziti-service':
                return this.authorizationService.canCreateServices();
            case 'ziti-endpoint':
                return this.authorizationService.canCreateEndpoints();
            case 'edge-router':
                return this.authorizationService.canCreateEdgeRouters();
            case 'edge-router-policy':
                return this.authorizationService.canCreateEdgeRouterPolicies();
            case 'posture-check':
                return this.authorizationService.canCreatePostureChecks();
            default:
                return false;
        }
    }
}
