import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Auth0Service} from "@netfoundry-ui/shared/authorization";

@Injectable({ providedIn: 'root' })
export class RedirectGuard {
  constructor(private auth: Auth0Service, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):any {
    // Check if the URL matches a specific route
    if (state.url.indexOf('/signup') >= 0) {
      window.location.href = 'https://netfoundry.io/pricing';
    } else {
      return true;
    }
  }
}
