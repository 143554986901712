import { Component, OnDestroy, OnInit } from '@angular/core';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import {
    ApiService,
    BrandingModeService,
    FeatureService,
    IdentityPreferencesService,
    LoggerService,
} from '@netfoundry-ui/shared/services';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { Subscription } from 'rxjs';
import _ from 'lodash';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
    company = '';
    domain = '';
    subscription = new Subscription();
    tenantLabel = '';
    userPreferences: any = {};
    orgPreferences: any = {};
    isLoading = false;

    constructor(
        public brandingService: BrandingModeService,
        public growlerService: GrowlerService,
        public featureService: FeatureService,
        public identityPreferences: IdentityPreferencesService,
        private apiService: ApiService,
        public authorizationService: AuthorizationService,
        private logger: LoggerService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        const html = document.getElementsByTagName('html')[0];

        this.brandingService.fetchBrandCss().then((rawCss) => {
            html.style.cssText = rawCss;
        });
        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                this.tenantLabel = tenant.label;
            })
        );
        this.apiService.currentUserPreferences.subscribe((userPreferences) => {
            this.userPreferences = _.cloneDeep(userPreferences?.preferences);
            if (!this.userPreferences) {
                this.userPreferences = {};
            }
            this.isLoading = false;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    toggleDarkMode() {
        this.brandingService.darkModeEnabled = !this.brandingService.darkModeEnabled;
        localStorage.setItem('darkMode_' + this.tenantLabel, this.brandingService.darkModeEnabled.toString());
    }

    togglePowerUserSetting() {
        if (!this.userPreferences) {
            this.userPreferences = {};
        }
        this.userPreferences.powerUserEnabled = !this.userPreferences?.powerUserEnabled;
        this.featureService.setPowerUserEnabled(this.userPreferences.powerUserEnabled);
    }

    toggleSideNavigationSetting() {
        if (!this.userPreferences) {
            this.userPreferences = {};
        }
        this.userPreferences.enableNewNavigation = !this.userPreferences?.enableNewNavigation;
        this.featureService.enableNewNavigation = this.userPreferences.enableNewNavigation;
    }

    copyToClipboard() {
        const setlocalStorage = Object.entries(localStorage);
        const bearerJwtObjectArray = setlocalStorage.find(([key]) => /@@auth0spa*/.test(key));
        const bearerJwtObject = JSON.parse(bearerJwtObjectArray[1]);
        const bearerJwt = bearerJwtObject.body.access_token;
        navigator.clipboard.writeText(bearerJwt);
        this.growlerService.show(
            new GrowlerData('success', 'Success', 'Copied to clipboard', 'Jwt token has been copied to your clipboard')
        );
    }
}
