import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule } from '@angular/forms';
import { UiPipesModule } from '@netfoundry-ui/ui/pipes';
import { SideNavigatorComponent } from './side-navigator/side-navigator.component';

@NgModule({
    imports: [CommonModule, RouterModule, ClickOutsideModule, FormsModule, UiPipesModule],
    declarations: [SideNavigatorComponent],
    exports: [SideNavigatorComponent],
})
export class FeatureNavigatorModule {}
