<div class="fullModal opened" id="AddNetworkModal">
    <div id="NetworkStage1">
        <div *ngIf="!isEditing" [hidden]="step !== 0" class="headerRow" id="NetworkCreation">
            <div [ngClass]="{ isAt: step === 0 }" class="stepItem">
                <div class="title" style="filter: brightness(50%)">It's time to deploy</div>
                <div class="subtitle">Create A New Network</div>
                <div class="row">
                    <div class="formForm">
                        <div *ngIf="networkGroupList.length > 1" id="NetworkGroupField">
                            <div class="label row">
                                Network Group
                                <span [hidden]="!errorNetworkGroup" class="error" id="ErrorNetworkGroup"
                                    >Input Required</span
                                >
                                <span [hidden]="errorNetworkGroup" class="specialnote">Required</span>
                            </div>
                            <select
                                [(ngModel)]="networkGroupId"
                                [disabled]="processing"
                                [ngClass]="{ error: errorNetworkGroup }"
                                id="NetworkGroupSelect"
                                (change)="validate2() && changeStoredSecrets()"
                            >
                                <option value="">Select A Network Group</option>
                                <option
                                    *ngFor="let organization of networkGroupList | sortby: 'organizationShortName'"
                                    [value]="organization.id"
                                >
                                    {{ organization.name }}
                                </option>
                            </select>
                            <span [hidden]="!errors['networkGroupId']" class="err">
                                {{ errors['networkGroupId'] }}
                            </span>
                        </div>

                        <div class="label row">
                            Network Name
                            <!-- <span [hidden]="!errorName" class="error" id="ErrorNetworkName">Input Required</span>
                            <span [hidden]="!errorNameLength" class="error" id="ErrorNetworkLength"
                                >Name must be at least 5 characters</span
                            >
                            <span [hidden]="!errorNameContent" class="error" id="ErrorNetworkNameContent"
                                >Network Name is invalid</span
                            >
                            <span [hidden]="errorName || errorNameLength || errorNameContent" class="specialnote"
                                >Required</span
                            > -->
                        </div>
                        <div class="row">
                            <input
                                [(ngModel)]="networkModel.name"
                                [ngClass]="{ error: errors['name'] }"
                                class="restrictNormal"
                                id="NewNetworkName"
                                maxlength="64"
                                ng-enter="save()"
                                (change)="validate2()"
                                placeholder="What would you like to call your network?"
                            />
                            <span [hidden]="!errors['name']" class="err">
                                {{ errors['name'] }}
                            </span>
                        </div>
                        <div class="label row">NETWORK SETTINGS</div>
                        <div class="darkBox flexColumnContainer">
                            <div class="row">
                                <div
                                    *ngIf="showPublicCertToggle"
                                    class="certificatepod inner switchContainer"
                                >
                                    <div class="certificatetext">
                                        <span>CREATE PUBLIC CERTFICIATE</span>
                                        <div
                                            class="infoicon"
                                            matTooltip="Enabling this creates an additional domain with a trusted public certificate. However, a public certificate is only necessary for BrowZer based access. Most network clients use the primary domain's private certificate. Upgrading to a public certificate can be done later when needed, so it's best to leave it disabled if unsure."
                                            matTooltipPosition="below"
                                        ></div>
                                    </div>
                                    <div
                                        (click)="toggleCertificate()"
                                        [ngClass]="{
                                            on: networkModel.publicCertEnabled,
                                            disabled: isEditing
                                        }"
                                        class="toggle"
                                        style="float: right"
                                    >
                                        <span [hidden]="!networkModel.publicCertEnabled" style="margin-left: -15px"
                                            >YES</span
                                        >
                                        <span [hidden]="networkModel.publicCertEnabled" style="margin-right: -15px"
                                            >NO</span
                                        >
                                        <div class="switch"></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="wizard inner switchContainer switchColumnContainer"
                                [ngClass]="{ disabled: isEditing }"
                            >
                                <div class="switchRow">
                                    <div class="label">
                                        Custom DNS
                                        <div
                                            class="infoicon"
                                            matTooltip="Enable use of a custom domain name when connecting to the network controller"
                                            matTooltipPosition="below"
                                        ></div>
                                    </div>
                                    <div class="switchGrid special">
                                        <div>
                                            <div
                                                (click)="!isEditing && toggleAlternateDomain()"
                                                [ngClass]="{
                                                    on:
                                                        (networkModel.alternateDomainName &&
                                                            networkModel.alternateDomainName !== '') ||
                                                        enableAltDomain
                                                }"
                                                class="toggle"
                                            >
                                                <span [hidden]="!enableAltDomain">YES&nbsp;&nbsp;&nbsp;</span>
                                                <span [hidden]="enableAltDomain">&nbsp;&nbsp;&nbsp;&nbsp;NO</span>
                                                <div class="switch"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="enableAltDomain">
                                    <div class="label sublabel">
                                        {{ isEditing ? 'Advertised Name' : 'Set Advertised Name' }}
                                    </div>
                                    <input
                                        (change)="validate()"
                                        (keyup.enter)="save()"
                                        [(ngModel)]="networkModel.alternateDomainName"
                                        [ngClass]="{ error: errorAlternateDomainName }"
                                        [disabled]="!enableAltDomain"
                                        id="AdvertisedName"
                                        placeholder="{{ isEditing ? '' : 'uid.mycompany.com' }}"
                                        type="text"
                                        class="fullInput"
                                    />
                                    <span [hidden]="!errorAlternateDomainName" class="err">
                                        Not a valid domain name
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="label row">NETWORK HOSTING</div>
                        <div *ngIf="!authorizationService.isSupportUser()" class="darkBox">
                            <div *ngIf="networkVersion !== 6 && isEnterprise" id="ProviderField">
                                <div class="label row" style="margin-top: 0">
                                    Network Provider
                                    <span [hidden]="!errorNetworkGroup" class="error" id="ErrorNetworkGroup"
                                        >Input Required</span
                                    >
                                    <span [hidden]="errorNetworkGroup" class="specialnote">Required</span>
                                </div>
                                <select
                                    (change)="providerChanged($event.target.value)"
                                    [(ngModel)]="networkModel.provider"
                                    [disabled]="processing"
                                    [ngClass]="{ error: errorProvider }"
                                    id="Providers"
                                >
                                    <option
                                        *ngFor="let provider of providers | sortby: 'city':'asc'; trackBy: trackById"
                                        [value]="provider.type"
                                    >
                                        {{ provider.type }}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="networkVersion !== 6 && !this.authorizationService.isSupportUser()" id="LocationField">
                                <div class="label row">
                                    Network Location
                                    <span [hidden]="!errorNetworkGroup" class="error" id="ErrorNetworkGroup"
                                        >Input Required</span
                                    >
                                    <span [hidden]="errorNetworkGroup" class="specialnote">Required</span>
                                </div>
                                <select
                                    [(ngModel)]="networkModel.locationCode"
                                    [disabled]="processing"
                                    [ngClass]="{ error: errorLocation }"
                                    id="NetworkLocationSelect"
                                    (change)="validate2()"
                                >
                                    <option [value]="undefined">Select a Region</option>
                                    <option
                                        *ngFor="
                                            let region of displayedRegions | sortby: 'city':'asc';
                                            trackBy: trackById
                                        "
                                        [value]="region.locationCode"
                                    >
                                        {{ region.city }} {{ region.locationCode }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="authorizationService.isSupportUser() && networkVersion !== 6 " class="row">
                            <mat-radio-group style="display: flex;" [(ngModel)]="selection">
                                <div style="width: 49%; display: inline-block">
                                    <div *ngIf="!isEditing" class="darkBox">
                                        <mat-radio-button (click)="disableNFHosted()" value="customerHosted"
                                            >CUSTOMER<br />HOSTED
                                        </mat-radio-button>
                                        <div
                                            class="infoicon"
                                            matTooltip="Customer hosts Network Controller in their own data center or cloud environment."
                                            matTooltipPosition="below"
                                            style="padding-top: 24px"
                                        ></div>
                                        <div class="customerHostedIcon"></div>
                                        <div *ngIf="selection !== 'netfoundryHosted'">
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="label row">
                                                <span [hidden]="errorNetworkGroup" class="note">Required</span>
                                                IP Address
                                            </div>
                                            </div>
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="grid">
                                                <input
                                                (change)="validate2()"
                                                (keyup.enter)="save()"
                                                [(ngModel)]="networkModel.customerProviderDetails.ipAddress"
                                                [disabled]="isEditing && !canEdit || selection === 'netfoundryHosted'"
                                                id="networkIPAddress"
                                                placeholder="Add your IP Address."
                                                type="text"
                                            />
                                            <span [hidden]="!errors['ipAddress']" class="err">
                                                {{ errors['ipAddress'] }}
                                            </span>
                                        </div>
                                        <div *ngIf="selection !== 'netfoundryHosted'">
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="label row">
                                                <span [hidden]="errorNetworkGroup" class="note">Required</span>
                                                Port
                                            </div>
                                            </div>
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="grid">
                                                <input
                                                (change)="validate2()"
                                                (keyup.enter)="save()"
                                                [(ngModel)]="networkModel.customerProviderDetails.sshPort"
                                                [disabled]="isEditing && !canEdit || selection === 'netfoundryHosted'"
                                                id="networkSSHPort"
                                                placeholder="Add your port"
                                                type="text"
                                            />
                                            <span [hidden]="!errors['sshPort']" class="err">
                                                {{ errors['sshPort'] }}
                                            </span>
                                        </div>
                                        <div *ngIf="selection !== 'netfoundryHosted'">
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="label row">
                                                <span [hidden]="errorNetworkGroup" class="note">Required</span>
                                                Ziti API Port
                                            </div>
                                            </div>
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="grid">
                                                <input
                                                (change)="validate2()"
                                                (keyup.enter)="save()"
                                                [(ngModel)]="networkModel.customerProviderDetails.managementPort"
                                                [disabled]="isEditing && !canEdit || selection === 'netfoundryHosted'"
                                                id="networkIPAddress"
                                                placeholder="Add the Management Port."
                                                type="text"
                                            />
                                            <span [hidden]="!errors['managementPort']" class="err">
                                                {{ errors['managementPort'] }}
                                            </span>
                                        </div>
                                        <div *ngIf="selection !== 'netfoundryHosted'">
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="label row">
                                                <span [hidden]="errorNetworkGroup" class="note">Required</span>
                                                SSH Key
                                            </div>
                                            </div>
                                            <div *ngIf="selection !== 'netfoundryHosted'" class="grid">
                                                <select
                                                (change)="validate2()"
                                                [(ngModel)]="networkModel.customerProviderDetails.sshAccessKeyPairName"
                                                [disabled]="(isEditing && !canEdit) || selection === 'netfoundryHosted'"
                                                id="SshKey"
                                            >
                                                <option [value]="undefined">Select an SSH Key</option>
                                                <option
                                                    *ngFor="let key of storedSecretList | sortby: 'name':'asc'"
                                                    [value]="key.name"
                                                >
                                                    {{ key.name }}
                                                </option>
                                            </select>
                                            <span [hidden]="!errors['sshAccessKeyPairName']" class="err">
                                                {{ errors['sshAccessKeyPairName'] }}
                                            </span>
                                        </div>
                                    </div>
                                    </div>
            
                                <div *ngIf="!isEditing" class="darkBox hostedBox" style="margin-left: 10px; height: 270px">
                                    <mat-radio-button (click)="disableCustomerHosted()" value="netfoundryHosted"
                                        >NETFOUNDRY<br />HOSTED
                                    </mat-radio-button>
                                    <div
                                        class="infoicon"
                                        matTooltip="NetFoundry creates the Network Controller in a NetFoundry Data Center that is publically accessible."
                                        matTooltipPosition="below"
                                        style="padding-top: 24px"
                                    ></div>
                                    <div class="netfoundryHostedIcon"></div>
                                    <div></div>
                                    <div class="grid">
                                        <div
                                            [ngClass]="{ error: errorHostProvider }"
                                            class="podlabel"
                                            style="color: var(--navigation); position: relative"
                                        >
                                            CLOUD PROVIDER<span class="note">REQUIRED</span>
                                        </div>
            
                                        <select
                                            (change)="providerChanged($event.target.value)"
                                            [(ngModel)]="networkModel.provider"
                                            [disabled]="(isEditing && !canEdit) || selection === 'customerHosted'"
                                            id="Providers"
                                        >
                                            <option
                                                *ngFor="let provider of providers | sortby: 'type':'asc'"
                                                [value]="provider.type"
                                            >
                                                {{ provider.type }}
                                            </option>
                                        </select>
                                        <span [hidden]="!errors['provider']" class="err">
                                            {{ errors['provider'] }}
                                        </span>
                                        <div
                                            class="podlabel"
                                            style="color: var(--navigation); position: relative"
                                        >
                                            REGION<span class="note">REQUIRED</span>
                                        </div>
            
                                        <select
                                            (change)="validate()"
                                            [(ngModel)]="networkModel.locationCode"
                                            [disabled]="(isEditing && !canEdit) || selection === 'customerHosted'"
                                            id="Region"
                                        >
                                            <option [value]="undefined">Select a Region</option>
                                            <option
                                                *ngFor="let region of displayedRegions | sortby: 'city':'asc'"
                                                [value]="region.locationCode"
                                            >
                                                {{ region.city }} {{ region.locationCode }}
                                            </option>
                                        </select>
                                        <span [hidden]="!errors['region']" class="err">
                                            {{ errors['region'] }}
                                        </span>
                                    </div>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="networkVersion === 7 && !(isSelfService || newSelfService)" id="NetworkJSONUpload">
                            <div class="label row">OR UPLOAD YOUR NETWORK AS CODE</div>
                            <div
                                (drop)="fileDrop()"
                                [ngClass]="{ invalid: !fileIsValid && fileIsReady, valid: fileIsValid && fileIsReady }"
                                [uploader]="uploader"
                                class="uploadArea"
                                ng2FileDrop
                            >
                                <div [hidden]="fileIsReady" class="label">Drag and drop a YML or JSON file</div>
                                <div [hidden]="fileIsReady" class="sublabel">
                                    or
                                    <div (click)="showDialog()" class="linkButton" id="NetworkFileUpload">
                                        Select a file from your computer
                                    </div>
                                    <form [formGroup]="form">
                                        <input
                                            #fileInput
                                            (change)="onFileChange($event)"
                                            id="FileSelect"
                                            name="file"
                                            style="display: none"
                                            type="file"
                                        />
                                    </form>
                                </div>
                                <div [hidden]="!fileIsReady || !fileIsValid" class="label">
                                    Click create to import network
                                </div>
                                <div [hidden]="!fileIsReady || fileIsValid" class="label">
                                    Upload file is invalid. Please check content
                                </div>
                                <div (click)="showDialog()" *ngIf="fileIsReady" class="linkButton">
                                    or choose a different file
                                </div>
                            </div>
                        </div>
                        <div class="row networkLimitError" [hidden]="!errors['networkLimit']">
                            <p-message
                            severity="error"
                            text="{{errors['networkLimit']}}"
                            ></p-message>
                        </div>
                        <div class="row right" style="margin-top: 25px">
                            <button
                                (click)="toggleEditor()"
                                *ngIf="fileIsReady && !isEditing && !importError"
                                [disabled]="importing"
                                class="save"
                                id="EditButton"
                            >
                                Edit
                            </button>
                            <button
                                (click)="toggleEditor()"
                                *ngIf="fileIsReady && isEditing && !importError"
                                [disabled]="importing"
                                class="save"
                                id="EditUploadButton"
                            >
                                Done
                            </button>
                            <button (click)="save()" *ngIf="!processing" class="save" id="CreateButton">
                                Create My Network
                            </button>
                            <button *ngIf="processing" class="save confirm" disabled>
                                Creating <span class="spinner"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEditing" [hidden]="step !== 1" class="headerRow" id="NetworkBuildingStatus">
            <div [ngClass]="{ isAt: step === 1 }" class="sized nextStep">
                <app-network-bulk-upload-save
                    #saveNetworkAsCode
                    (uploaded)="close()"
                    [businessKey]="businessKey"
                    [network]="networkModel"
                >
                </app-network-bulk-upload-save>
            </div>
        </div>
        <div *ngIf="isEditing" class="headerRow" id="NetworkValidationStatus">
            <div *ngIf="!isEditingResource">
                <div [ngClass]="{ isAt: isEditing }" class="sized nextStep">
                    <div class="title" style="filter: brightness(50%)">Edit Network Upload</div>
                    <div class="subtitle">View and edit your network upload</div>
                </div>
                <div class="row">
                    <div class="formForm">
                        <div
                            (click)="toggleResourceEditor('ziti-app-wan')"
                            [ngClass]="{ invalid: appWANsInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-appwans"></div>
                            {{ featureService.enableNewNavigation ? 'Service Policies' : 'AppWANs' }}
                            <div
                                [ngClass]="{ 'icon-invalid': appWANsInvalid, 'icon-valid': !appWANsInvalid }"
                                class="image"
                            ></div>
                        </div>
                        <div
                            (click)="toggleResourceEditor('ziti-service')"
                            [ngClass]="{ invalid: servicesInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-services"></div>
                            Services
                            <div
                                [ngClass]="{ 'icon-invalid': servicesInvalid, 'icon-valid': !servicesInvalid }"
                                class="image"
                            ></div>
                        </div>
                        <div
                            (click)="toggleResourceEditor('ziti-endpoint')"
                            [ngClass]="{ invalid: endpointsInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-endpoints"></div>
                            {{ featureService.enableNewNavigation ? 'Identities' : 'Endpoints' }}
                            <div
                                [ngClass]="{ 'icon-invalid': endpointsInvalid, 'icon-valid': !endpointsInvalid }"
                                class="image"
                            ></div>
                        </div>
                        <div
                            (click)="toggleResourceEditor('edge-router')"
                            [ngClass]="{ invalid: edgeRoutersInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-routers"></div>
                            Edge Routers
                            <div
                                [ngClass]="{ 'icon-invalid': edgeRoutersInvalid, 'icon-valid': !edgeRoutersInvalid }"
                                class="image"
                            ></div>
                        </div>
                        <div
                            (click)="toggleResourceEditor('edge-router-policy')"
                            [ngClass]="{ invalid: edgeRouterPoliciesInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-routers"></div>
                            Edge Router Policies
                            <div
                                [ngClass]="{
                                    'icon-invalid': edgeRouterPoliciesInvalid,
                                    'icon-valid': !edgeRouterPoliciesInvalid
                                }"
                                class="image"
                            ></div>
                        </div>
                        <div
                            (click)="toggleResourceEditor('posture-check')"
                            [ngClass]="{ invalid: postureChecksInvalid }"
                            class="resource-type-label"
                        >
                            <div class="image icon-posturechecks"></div>
                            Posture Checks
                            <div
                                [ngClass]="{
                                    'icon-invalid': postureChecksInvalid,
                                    'icon-valid': !postureChecksInvalid
                                }"
                                class="image"
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="row buttonRow right">
                    <div class="formForm">
                        <button (click)="toggleEditor()" class="save" id="DoneButton">Done</button>
                    </div>
                </div>
            </div>
            <app-upload-entities
                (doneEditing)="doneEditing($event)"
                *ngIf="isEditingResource"
                [resourceData]="resourceData"
            ></app-upload-entities>
        </div>
    </div>

    <div (click)="close()" class="buttonBall close" id="CloseButton">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
