import { Resource, HateoasResource } from '@lagoshny/ngx-hateoas-client';
import { EmbeddedHostV2 } from './embedded-host';
@HateoasResource('network-controllers')

export class NetworkController extends Resource {
    id: string;
    name: string;
    ipAddress: string;
    zitiId: string;
    hostId: string;
    locationMetadataId: string;
    provider: string;
    providerId: string;
    status: string;
    userData: string;
    createdAt: string;
    updatedAt: string;
    selected = false;
    host: EmbeddedHostV2;
    region: string;
    _embedded: {
        host: {
            provider: string;
        };
    };
}
