<div [ngClass]="{ inline: isInline }" class="fullModal opened" id="ZitiRouterAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="!isEditing">Create A New Edge Router</span>
            <span *ngIf="isEditing">Edit Your Edge Router</span>
        </div>
        <div *ngIf="!isEditing" class="subtitle">Enter your routers details</div>
        <div *ngIf="isEditing" class="subtitle">Edit your routers details</div>
        <div
            class="subhelp"
            matTooltip="Edge Routers are fundamental to all NetFoundry Networks and serve two vital roles. First, Edge Routers are the building blocks of the NetFoundry mesh network. Second, Edge Routers provide the necessary access points to Endpoints onto any NetFoundry network."
            matTooltipPosition="below"
        >
            What's a Edge Router?
        </div>
        <div class="sized">
            <div class="label row">
                Router Name
                <div *ngIf="!isEditing" class="step">1</div>
                <span [hidden]="errors['name']" class="note">Required</span>
            </div>

            <div class="row">
                <input
                    (change)="validate()"
                    (keyup.enter)="save()"
                    [(ngModel)]="model.name"
                    [disabled]="isEditing && !canEdit"
                    [ngClass]="{ error: errors['name'] }"
                    id="ZRouterName"
                    maxlength="64"
                    minlength="5"
                    placeholder="Name this router"
                    type="text"
                />
                <span [hidden]="!errors['name']" class="err">
                    {{ errors['name'] }}
                </span>
            </div>

            <div *ngIf="registered && isEditing" class="label row">
                Edge Router Hosting<span class="note">Enabled</span>
            </div>
            <div *ngIf="registered && isEditing" class="label row edgerouterregion">
                Edge Router Region
                <div class="row">
                    <span *ngIf="model.region"> {{ model.provider }} {{ model.region }} </span>
                </div>
            </div>
            <div *ngIf="model.id" class="label softwareAgentLabel row">
                Software Management Agent
                <div
                class="infoicon"
                matTooltip="The Software Management Agent facilitates remote support activities such as upgrades and application log retrieval. Please note the agent must be online to complete an upgrade."
                matTooltipPosition="below"
            ></div>
            </div>
            <div *ngIf="isEditing" class="label row softwareAgent">

                <div *ngIf="deploymentStatus === true" class="row">
                    <i class="pi pi-check-circle" [ngClass]="{disabled: disabled}"  style="color: #00db48; font-size: 1.25rem;"></i>
                    <span>&nbsp;Online &nbsp;<p-button *ngIf="refreshClicked === false"  style="position: absolute; right: 0;" icon="pi pi-refresh" (click)="refreshSoftwareDeploymentState()" [rounded]="true" [raised]="true" [text]="true"></p-button> </span>
                    <i *ngIf="refreshClicked === true"  class="pi pi-spin pi-spinner" style="font-size: 1.25rem; position: absolute; right: 0;"></i>
                </div>
                <div *ngIf="softwareRegistered === false && deploymentStatus === false " class="row">
                    <i class="pi pi-exclamation-circle" [ngClass]="{disabled: disabled}"  style="color: grey; font-size: 1.25rem;"></i>
                    <span>&nbsp;Unregistered &nbsp;<p-button *ngIf="refreshClicked === false"  style="position: absolute; right: 0;" icon="pi pi-refresh" (click)="refreshSoftwareDeploymentState()" [rounded]="true" [raised]="true" [text]="true"></p-button> </span>
                    <i *ngIf="refreshClicked === true"  class="pi pi-spin pi-spinner" style="font-size: 1.25rem; position: absolute; right: 0;"></i>
                </div>
                <div *ngIf="deploymentStatus === false && softwareRegistered === true" class="row">
                    <i class="pi pi-ban" [ngClass]="{diabled: disabled}"  style="color: red; font-size: 1.25rem;"></i>
                    <span>&nbsp;Offline&nbsp;<p-button *ngIf="refreshClicked === false" icon="pi pi-refresh" style="position: absolute; right: 0;" (click)="refreshSoftwareDeploymentState()" [rounded]="true" [raised]="true" [text]="true"></p-button> </span>
                    <i *ngIf="refreshClicked === true" class="pi pi-spin pi-spinner" style="font-size: 1.25rem; position: absolute; right: 0;"></i>
                </div>

            </div>

            <div *ngIf="registered && isEditing && wssAllowed" class="label row">WSS Enabled</div>
            <div *ngIf="registered && isEditing && wssAllowed" class="label row wssListenerEdit">
                <span> {{ model.wssListener }} </span>
            </div>
            <div class="row" *ngIf="isEditing && !isCustomerHosted">
                <div class="grid">
                    <div class="label" style="position: relative">Link Listener<span class="note">OPTIONAL</span></div>
                    <div class="wizard inner switchContainer">
                        <div class="label">
                            Enable LINK LISTENER
                            <div
                                class="infoicon"
                                matTooltip="Allow incoming connections from other routers. All routers will try to dial all other listening routers, so it's best to only listen if publicly accessible. This requires the firewall to open to incoming 80/tcp connections."
                                matTooltipPosition="below"
                            ></div>
                        </div>
                        <div class="switchGrid special">
                            <div>
                                <div
                                    (click)="canEdit && toggleLinkListener()"
                                    [ngClass]="{ on: model.linkListener }"
                                    class="toggle"
                                >
                                    <span [hidden]="!model.linkListener">ON&nbsp;&nbsp;&nbsp;</span>
                                    <span [hidden]="model.linkListener">&nbsp;&nbsp;&nbsp;&nbsp;OFF</span>
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="label row" *ngIf="!whiteLabelSupported">
                Router Attributes
                <div
                    class="infoicon"
                    matTooltip="Router Attributes are tags applied to a router. Apply the same tag to other routers to form a group of routers."
                    matTooltipPosition="below"
                ></div>
                <div *ngIf="!isEditing" class="step">2</div>
                <span class="note">Optional</span>
            </div>

            <div class="row" *ngIf="!whiteLabelSupported">
                <app-tags-selector
                    id="edgeRouter"
                    [availableAttributes]="edgeRouterAttributes"
                    [selectedAttributes]="selectedEdgeRouterAttributes"
                    [disableField]="isEditing && !canEdit"
                    [hasError]="attributeError"
                    [placeholder]="'Add attributes for grouping routers'"
                    [isLoading]="isLoadingEdgeRouterAttr"
                    (addAttribute)="addEdgeRouterAttribute($event)"
                    (removeAttribute)="removeEdgeRouterAttribute($event)"
                    [errorMessage]="errors['attributes']"
                ></app-tags-selector>
            </div>
            <div class="label row" *ngIf="whiteLabelSupported">
                Router Settings
                <div
                    class="infoicon"
                    matTooltip="Router Attributes are tags applied to a router. Apply the same tag to other routers to form a group of routers."
                    matTooltipPosition="below"
                ></div>
                <div *ngIf="!isEditing" class="step">2</div>
            </div>
            <div class="darkBox flexColumnContainer" *ngIf="whiteLabelSupported">
                <div class="wizard inner switchContainer" *ngIf="featureService.experimentalFeatures">
                    <div class="label">
                        Enable Router
                        <div
                            class="infoicon"
                            matTooltip="Enable or disable this router for use in your network"
                            matTooltipPosition="below"
                        ></div>
                    </div>
                    <div class="switchGrid special">
                        <div>
                            <div
                                (click)="(!isEditing || canEdit) && toggleEnabled()"
                                [ngClass]="{ on: model.enabled }"
                                class="toggle"
                            >
                                <span [hidden]="!model.enabled">ON&nbsp;&nbsp;&nbsp;</span>
                                <span [hidden]="model.enabled">&nbsp;&nbsp;&nbsp;&nbsp;OFF</span>
                                <div class="switch"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        [ngClass]="{ error: errorHostProvider }"
                        class="podlabel"
                        style="color: var(--navigation); position: relative"
                    >
                        <span>SELECT OR CREATE ROUTER ATTRIBUTES</span>
                        <span class="note">OPTIONAL</span>
                        <div
                            class="infoicon"
                            matTooltip="Router Attributes are tags applied to a router. Apply the same tag to other routers to form a group of routers."
                            matTooltipPosition="below"
                        ></div>
                    </div>
                    <app-tags-selector
                        (addAttribute)="addEdgeRouterAttribute($event)"
                        (removeAttribute)="removeEdgeRouterAttribute($event)"
                        [availableAttributes]="edgeRouterAttributes"
                        [disableField]="isEditing && !canEdit"
                        [errorMessage]="errors['attributes']"
                        [hasError]="attributeError"
                        [isLoading]="isLoadingEdgeRouterAttr"
                        [placeholder]="'Add attributes for grouping routers'"
                        [selectedAttributes]="selectedEdgeRouterAttributes"
                        id="edgeRouter"
                    ></app-tags-selector>
                </div>
                <div
                    class="wizard inner switchContainer switchColumnContainer"
                    [ngClass]="{ disabled: isEditing }"
                    *ngIf="selection === 'netfoundryHosted' && !isCustomerHosted"
                >
                    <div class="switchRow">
                        <div class="label">
                            Custom DNS
                            <div
                                class="infoicon"
                                matTooltip="Enable use of a custom domain name when accessing this edge router"
                                matTooltipPosition="below"
                            ></div>
                        </div>
                        <div class="switchGrid special">
                            <div>
                                <div
                                    (click)="!isEditing && toggleAlternateDomain()"
                                    [ngClass]="{
                                        on:
                                            (model.alternateDomainName && model.alternateDomainName !== '') ||
                                            enableAltDomain
                                    }"
                                    class="toggle"
                                >
                                    <span
                                        [hidden]="
                                            !enableAltDomain &&
                                            (!model.alternateDomainName || model.alternateDomainName === '')
                                        "
                                        >YES&nbsp;&nbsp;&nbsp;</span
                                    >
                                    <span
                                        [hidden]="
                                            enableAltDomain ||
                                            (model.alternateDomainName && model.alternateDomainName !== '')
                                        "
                                        >&nbsp;&nbsp;&nbsp;&nbsp;NO</span
                                    >
                                    <div class="switch"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row"
                        *ngIf="enableAltDomain || (model.alternateDomainName && model.alternateDomainName !== '')"
                    >
                        <div class="label sublabel">
                            {{ isEditing ? 'Advertised Name' : 'Set Advertised Name' }}
                        </div>
                        <input
                            (change)="validate()"
                            (keyup.enter)="save()"
                            [(ngModel)]="model.alternateDomainName"
                            [disabled]="(isEditing && !canEdit) || !enableAltDomain"
                            [ngClass]="{ error: errors['alternateDomainName'] }"
                            id="AdvertisedName"
                            placeholder="{{ isEditing ? '' : 'uid.mycompany.com' }}"
                            type="text"
                            class="fullInput"
                        />
                        <span [hidden]="!errors['alternateDomainName']" class="err">
                            {{ errors['alternateDomainName'] }}
                        </span>
                    </div>
                    <div class="row" style="margin-top: 10px" *ngIf="isEditing">
                        <div class="label sublabel">Netfoundry DNS</div>
                        <input [(ngModel)]="model.domainName" id="NFDomainName" type="text" class="fullInput" />
                        <span [hidden]="!errors['alternateDomainName']" class="err">
                            {{ errors['alternateDomainName'] }}
                        </span>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing && model.provider !== 'CUSTOMER'" class="label row">
                <div class="step">3</div>
                <div>Select a hosting type</div>
                <div>
                    <span class="note">Required</span>
                </div>
            </div>
            <div class="row">
                <mat-radio-group [(ngModel)]="selection">
                    <div style="width: 49%; display: inline-block">
                        <div *ngIf="!isEditing" class="darkBox">
                            <mat-radio-button (click)="disableNFHosted()" value="customerHosted"
                                >CUSTOMER<br />HOSTED
                            </mat-radio-button>
                            <div
                                class="infoicon"
                                matTooltip="Customer hosts Edge Router in their own data center or cloud environment."
                                matTooltipPosition="below"
                                style="padding-top: 24px"
                            ></div>
                            <div class="customerHostedIcon"></div>
                            <div>
                                <!-- <div class="title">CUSTOMER HOSTED</div> -->
                            </div>
                        </div>
                        <div *ngIf="!isEditing" class="grid">
                            <div class="label" style="position: relative">
                                Link Listener<span class="note">OPTIONAL</span>
                            </div>
                            <div class="wizard inner switchContainer">
                                <div class="label">
                                    Enable LINK LISTENER
                                    <div
                                        class="infoicon"
                                        matTooltip="Allow incoming connections from other routers. All routers will try to dial all other listening routers, so it's best to only listen if publicly accessible. This requires the firewall to open to incoming 80/tcp connections."
                                        matTooltipPosition="below"
                                    ></div>
                                </div>
                                <div class="switchGrid special">
                                    <div>
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleLinkListener()"
                                            [ngClass]="{ on: model.linkListener }"
                                            class="toggle"
                                        >
                                            <span [hidden]="!model.linkListener">ON&nbsp;&nbsp;&nbsp;</span>
                                            <span [hidden]="model.linkListener">&nbsp;&nbsp;&nbsp;&nbsp;OFF</span>
                                            <div class="switch"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="!isEditing && featureService.powerUserEnabled && wssAllowed"
                            class="grid wssListener"
                            [ngClass]="{ disabled: !isHosted }"
                        >
                            <div class="label topLabel" style="position: relative">
                                WSS Listener<span class="note">OPTIONAL</span>
                            </div>
                            <div class="wizard inner switchContainer">
                                <div class="label">
                                    Enable WSS
                                    <div
                                        class="infoicon"
                                        matTooltip="When turned on, this allows authorized endpoints to connect to this Edge Router via a Secure Web Socket (WSS) connection.  Only BrowZer based endpoints require this connection type at this time."
                                        matTooltipPosition="below"
                                    ></div>
                                </div>
                                <div class="switchGrid special">
                                    <div>
                                        <div
                                            (click)="(!isEditing || canEdit) && toggleWssListener()"
                                            [ngClass]="{ on: model.wssListener }"
                                            class="toggle"
                                            matTooltip="{{
                                                isHosted ? '' : 'WSS can only be enabled for NetFoundry Hosted Routers'
                                            }}"
                                            matTooltipPosition="below"
                                        >
                                            <span [hidden]="!model.wssListener">ON&nbsp;&nbsp;&nbsp;</span>
                                            <span [hidden]="model.wssListener">&nbsp;&nbsp;&nbsp;&nbsp;OFF</span>
                                            <div class="switch"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isEditing" class="darkBox hostedBox" style="margin-left: 10px; height: 345px">
                        <mat-radio-button (click)="disableCustomerHosted()" value="netfoundryHosted"
                            >NETFOUNDRY<br />HOSTED
                        </mat-radio-button>
                        <div
                            class="infoicon"
                            matTooltip="NetFoundry creates the Edge Router in a NetFoundry Data Center that is publically accessible."
                            matTooltipPosition="below"
                            style="padding-top: 24px"
                        ></div>
                        <div class="netfoundryHostedIcon"></div>
                        <div></div>
                        <div class="grid">
                            <div
                                [ngClass]="{ error: errorHostProvider }"
                                class="podlabel"
                                style="color: var(--navigation); position: relative"
                            >
                                CLOUD PROVIDER<span class="note">REQUIRED</span>
                            </div>

                            <select
                                (change)="providerChanged($event.target.value)"
                                [(ngModel)]="model.provider"
                                [disabled]="(isEditing && !canEdit) || selection === 'customerHosted'"
                                [ngClass]="{ error: errorHostProvider }"
                                id="Providers"
                            >
                                <option
                                    *ngFor="let provider of providers | sortby: 'type':'asc'"
                                    [value]="provider.type"
                                >
                                    {{ provider.type }}
                                </option>
                            </select>
                            <span [hidden]="!errors['provider']" class="err">
                                {{ errors['provider'] }}
                            </span>
                            <div
                                [ngClass]="{ error: errorHostProvider }"
                                class="podlabel"
                                style="color: var(--navigation); position: relative"
                            >
                                REGION<span class="note">REQUIRED</span>
                            </div>

                            <select
                                (change)="validate()"
                                [(ngModel)]="model.locationCode"
                                [disabled]="(isEditing && !canEdit) || selection === 'customerHosted'"
                                [ngClass]="{ error: errors['region'] }"
                                id="Region"
                            >
                                <option [value]="undefined">Select a Datacenter</option>
                                <option
                                    *ngFor="let region of displayedRegions | sortby: 'city':'asc'"
                                    [value]="region.locationCode"
                                >
                                    {{ region.city }} {{ region.locationCode }}
                                </option>
                            </select>
                            <span [hidden]="!errors['region']" class="err" style="padding-top: 10px">
                                {{ errors['region'] }}
                            </span>
                        </div>
                    </div>
                </mat-radio-group>
            </div>

            <div
                *ngIf="!registered && isEditing && !model.dataCenterId && model.status === 'PROVISIONED'"
                class="sized"
            >
                <div class="editsubtitle">Register your router in two simple steps.</div>
                <div class="steptext">
                    <div class="step">1</div>
                    Copy Your Registration Key
                </div>
                <div *ngIf="model.status === 'PROVISIONED'" class="label row">
                    {{ model.name }} Registration Key<span class="note">Tap to Copy</span>
                </div>
                <div *ngIf="model.status === 'PROVISIONED'" class="row">
                    <div (click)="getEdgeRouterRegKey()" *ngIf="!jwtExpired" class="downloadButton">
                        <div class="DownloadKey"></div>
                        <div>REGISTRATION KEY</div>
                        <div class="TapToDownload"></div>
                    </div>
                    <div
                        *ngIf="!jwtExpired && expiration"
                        class="label row"
                        style="text-align: center; color: red; padding: 10px"
                    >
                        Expires {{ jwtExpiration }}
                    </div>

                    <div
                        (click)="reissueJWT()"
                        *ngIf="hostProvider === 'CUSTOMER' && jwtExpired"
                        class="downloadButton"
                    >
                        <div class="DownloadKey"></div>
                        <div>REISSUE KEY</div>
                        <div class="TapToDownload"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="!registered && isEditing && !this.model.dataCenterId" class="row expansion advanced-downloads">
                <mat-accordion>
                    <mat-expansion-panel #panel [hideToggle]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'70px'">
                            <mat-panel-title class="expansionLabel">
                                <div class="advanceddownloads"></div>
                                <div class="subtitle" style="margin-left: -100px">Advanced Downloads</div>
                                <span
                                    [ngClass]="{ open: panel.expanded }"
                                    class="expansion-indicator icon-Sort_Down"
                                ></span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-grid-list cols="10" rowHeight="45px">
                            <mat-grid-tile [colspan]="9">
                                <div
                                    (click)="downloadJWT()"
                                    [ngClass]="{ 'getting-jwt': gettingJwt, 'link-disabled': !model.jwt }"
                                    [title]="
                                        !model.jwt && gettingJwt
                                            ? 'Waiting for JWT to become available for download'
                                            : !model.jwt && !gettingJwt
                                            ? 'JWT is not available to download'
                                            : ''
                                    "
                                    class="label"
                                >
                                    <a class="expansionlink">Download JWT</a>
                                    <span *ngIf="gettingJwt" class="spinner"></span>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div (click)="getEdgeRouterConfig()" class="label">
                                    <a class="expansionlink">Download Config</a>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div *ngIf="!registered && isEditing && !this.model.dataCenterId" class="sized">
                <div class="steptext">
                    <div class="step">2</div>
                    Select a VM stack
                </div>
            </div>

            <div
                *ngIf="!registered && isEditing && !this.model.dataCenterId && this.isCustomerHosted"
                class="row center"
                style="margin-top: 20px"
            >
                <div class="sized">
                    <div (click)="openDownloads()" class="downloadInstallerButton">
                        <div class="DownloadVM"></div>
                        <div>Get The VM</div>
                        <div class="DownloadInstaller"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="!isEditing || canEdit" class="row buttonRow right" style="margin-top: 20px">
                <button (click)="save()" *ngIf="!processing && !isLoading" class="save" id="SaveEdgeRouterZitiButton">
                    {{ bulkEdit ? 'Done' : isEditing ? 'Update' : 'Create' }}
                </button>

                <button *ngIf="isLoading || processing" class="save confirm" disabled>
                    {{ isLoading ? 'Loading' : 'Saving' }}
                    <span class="spinner"></span>
                </button>
            </div>

            <div *ngIf="featureService.powerUserEnabled" class="associated row">
                <div *ngIf="isEditing" class="grid half thin">
                    <app-preview-list
                        (itemSelected)="serviceSelected($event)"
                        [allNames]="assocServices"
                        [clickable]="!isInline"
                        [isLoading]="isLoadingAssocServices"
                        label="Associated Services"
                    ></app-preview-list>
                    <app-preview-list
                        (itemSelected)="endpointSelected($event)"
                        [allNames]="assocEndpoints"
                        [clickable]="!isInline"
                        [isLoading]="isLoadingAssocEndpoints"
                        label="Associated Endpoints"
                    ></app-preview-list>
                </div>
            </div>
            <div *ngIf="isRouterManaged" class="row" style="padding-bottom: 24px">
                <div class="managed">
                    This Edge Router system manages the following Endpoint:
                    <a (click)="endpointSelected(managedEndpoints[0])"> {{ managedEndpoints[0] }}</a>
                </div>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete || selection === 'netfoundryHosted'" class="fullRow" id="Stage3">
        <div class="title" style="filter: brightness(50%)">Congratulations</div>
        <div class="subtitle">Your Edge Router {{ completedTitleStatusString }}</div>
        <div [ngClass]="{ building: isBuilding, created: !isBuilding }" class="row center">
            <span *ngIf="isBuilding" class="completedEndpointBubble">
                <svg class="circular" viewBox="25 25 50 50">
                    <circle
                        class="path"
                        cx="50"
                        cy="50"
                        fill="none"
                        r="20"
                        stroke-miterlimit="10"
                        stroke-width="5"
                    ></circle>
                </svg>
            </span>
        </div>

        <div *ngIf="!model.dataCenterId" class="sized">
            <div class="editsubtitle">Register your router in two simple steps.</div>
            <div class="steptext">
                <div class="step">1</div>
                Copy Your Registration Key
            </div>
            <div class="label row">{{ model.name }} Registration Key<span class="note">TAP TO COPY</span></div>
            <div class="row" style="margin-bottom: 20px">
                <div (click)="getEdgeRouterRegKey()" class="downloadButton">
                    <div class="DownloadKey"></div>
                    <div>REGISTRATION KEY</div>
                    <div class="TapToDownload"></div>
                </div>
            </div>
        </div>

        <div *ngIf="!model.dataCenterId" class="sized label">
            Advanced Downloads<span class="note">Open to Download</span>
        </div>
        <div *ngIf="!model.dataCenterId" class="sized">
            <div class="row advanced-downloads">
                <mat-accordion>
                    <mat-expansion-panel #panel [hideToggle]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'70px'">
                            <mat-panel-title class="expansionLabel">
                                <div class="advanceddownloads"></div>
                                <div class="subtitle" style="margin-left: -100px">Advanced Downloads</div>
                                <span
                                    [ngClass]="{ open: panel.expanded }"
                                    class="expansion-indicator icon-Sort_Down"
                                ></span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-grid-list cols="10" rowHeight="45px">
                            <mat-grid-tile [colspan]="9">
                                <div
                                    (click)="downloadJWT()"
                                    [ngClass]="{ 'getting-jwt': gettingJwt, 'link-disabled': !model.jwt }"
                                    [title]="
                                        !model.jwt && gettingJwt
                                            ? 'Waiting for JWT to become available for download'
                                            : !model.jwt && !gettingJwt
                                            ? 'JWT is not available to download'
                                            : ''
                                    "
                                    class="label"
                                >
                                    <a class="expansionlink">Download JWT</a>
                                    <span *ngIf="gettingJwt" class="spinner"></span>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="9">
                                <div (click)="getEdgeRouterConfig()" class="label">
                                    <a class="expansionlink">Download Config</a>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div *ngIf="!model.dataCenterId" class="sized">
            <div class="steptext">
                <div class="step">2</div>
                Get the VM
            </div>
        </div>

        <div *ngIf="!model.dataCenterId" class="row center" style="margin-top: 20px; margin-bottom: 20px">
            <div class="sized">
                <div (click)="openDownloads()" class="downloadInstallerButton">
                    <div class="DownloadVM"></div>
                    <div>Get The VM</div>
                    <div class="DownloadInstaller"></div>
                </div>
            </div>
        </div>
    </div>

    <div (click)="getMetrics()" *ngIf="isEditing" class="buttonBall metrics" id="MetricsButton">
        <div class="buttonLabel">METRICS</div>
    </div>

    <div (click)="getEventsData()" *ngIf="isEditing" class="buttonBall events" id="EventsButton">
        <div class="buttonLabel">Events</div>
    </div>

    <div (click)="share()" *ngIf="canShareEdgeRouter" class="buttonBall share" id="ShareEditEdgeRouterButton">
        <div class="buttonLabel">SHARE</div>
    </div>
    <div *ngIf="!canShareEdgeRouter && isEditing" (click)="timeoutForceSync()" class="buttonBall refresh" id="ForceSyncButton">
        <i *ngIf="syncClicked === false" class="pi pi-sync" style="color: gray; font-size: 1.3rem; padding-bottom: 10px;"></i>
        <i *ngIf="syncClicked === true" class="pi pi-spin pi-spinner" style=" color: gray; font-size: 1.35rem; margin-bottom: 10px;"></i>
        <div class="buttonLabel">SYNC</div>
    </div>
    <div (click)="hide()" class="buttonBall close" id="EscZRouterClose">
        <div class="buttonLabel">ESC</div>
    </div>
    <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
</div>
