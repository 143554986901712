import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SETTINGS_SERVICE, SettingsService } from 'ziti-console-lib';

import { isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ZitiApiInterceptor implements HttpInterceptor {
    constructor(@Inject(SETTINGS_SERVICE) private settingsService: SettingsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isEmpty(this.settingsService?.settings?.session)) {
            return next.handle(req);
        } else {
            return next.handle(this.addAuthToken(req));
        }
    }

    private addAuthToken(request: any) {
        const session = this.settingsService.settings.session;
        return request.clone({ setHeaders: { 'zt-session': session.id } });
    }
}
